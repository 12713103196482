// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-ansichtssache-jsx": () => import("../src/pages/ansichtssache.jsx" /* webpackChunkName: "component---src-pages-ansichtssache-jsx" */),
  "component---src-pages-bread-and-hops-jsx": () => import("../src/pages/bread-and-hops.jsx" /* webpackChunkName: "component---src-pages-bread-and-hops-jsx" */),
  "component---src-pages-impressum-jsx": () => import("../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-presse-jsx": () => import("../src/pages/presse.jsx" /* webpackChunkName: "component---src-pages-presse-jsx" */),
  "component---src-pages-road-to-bakery-jsx": () => import("../src/pages/road-to-bakery.jsx" /* webpackChunkName: "component---src-pages-road-to-bakery-jsx" */),
  "component---src-pages-sortiment-jsx": () => import("../src/pages/sortiment.jsx" /* webpackChunkName: "component---src-pages-sortiment-jsx" */)
}

